.compatibility{
    padding-top: 60px;
    padding-bottom: 90px;
}
.compatibility .inner-container{
    display: flex;
    gap: 20px;
}

.compatibility .inner-container .box .image-container{
    margin-bottom: 20px;
}
.compatibility .inner-container .box img{
    width: 100%;
    border-radius: 7px;
    aspect-ratio: 14/9;
    object-fit: cover;
}
.compatibility .inner-container .box h3{
    font-size: var(--font-size-lg);
    font-size: 1.6rem;
    font-family: 'pentora';
}

.compatibility .inner-container .box p{
    font-size: 1.2rem;
    line-height: 1.8rem;
    margin: 0;
}