.how-it-work .inner-container{
display: flex;
align-items: center;
gap: 50px;
}

.how-it-work .inner-container .box{
    flex: 1;
}

.how-it-work .inner-container p{
    margin: 0;
    margin-bottom: 25px;
}

.how-it-work .inner-container .box video{
    width: 80%;
}