/* Hero Section Styles */
.hero-section .container {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
  }
  
  @media (min-width: 1024px) {
    .hero-section .container {
      flex-direction: row; 
      position: relative;
      /* z-index: 2; */
    }
  }
  
  .hero-section .text-content {
    text-align: center;
    margin-top: var(--spacing-lg);
  }
  
  @media (min-width: 1024px) {
    .hero-section .text-content {
      text-align: left;
      margin-top: 0;
      width: 50%;
    }
  }
  
  @media (min-width: 1024px) {
    .hero-section .text-content h1 {
      font-size: var(--font-size-2xl); /* Larger font for desktops */
    }
  }
  
  .hero-section .text-content p {
    font-size: var(--font-size-lg);
    line-height: 1.6;
    margin-bottom: var(--spacing-lg);
  }