.scheduling.section-style{
    padding-top: 80px;
    padding-bottom: 90px;
}

/* Steps Container */
.steps {
    display: flex;
    justify-content: center;
}

/* Step with Arrow-Like Header */
.step {
    /* flex: 1; */
    /* min-width: 250px; */
    width: 345px;
    /* background-color: #1A0C3A; Dark purple background */
    border-radius: 8px;
    text-align: left;
    padding: 0;
    position: relative;
    overflow: hidden;
}

.step-header {
    /* background: #6C4AB6; Purple color for header */
    /* height: 50px; */
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
}

.step-number {
    font-size: 1.2rem;
    font-weight: bold;
    color: #fff;
    z-index: 2;
}
.scheduling .step-number span{
    cursor: text;
    display: -webkit-box;
    font-family: 'pentora';
    font-weight: bold;
    font-size: var(--font-size-l);
    font-size: 1.6rem;
    font-weight: inherit;
    text-align: inherit;
    width: 100%;
    height: 1em;
    padding-top: 0px;
    padding-bottom: 0px;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: var(--chakra-line-clamp);
    --chakra-line-clamp: 1;
    line-height: 1;
}
.css-17uqvha {
    position: absolute;
    inset: 0px;
    overflow: visible;
}

#pointer{
    display: flex
;
    -webkit-box-pack: center;
    justify-content: center;
    line-height: 1;
    position: relative;
    width: 100%;
    height: 3em;
    text-align: center;
    -webkit-box-align: center;
    align-items: center;
    --flex-align: center;
}
.themed-svg-shape-background {
    fill: #2f1d63ff;
    stroke: #48367cff;
    stroke-width: var(--shape-border-width);
    vector-effect: non-scaling-stroke;
    stroke-linejoin: round;
    stroke-linecap: round;
    filter: #48367cff;
}

.scheduling .step .step-content{
    padding: 15px;
}

.scheduling .step .step-content h2{
    font-family: 'pentora';
    font-weight: 600;
    letter-spacing: .5px;
}

.scheduling .step .step-content p {
    font-size: var(--font-size-lg);
    line-height: var(--font-size-l);
}