.submissions-table-container{
    margin: auto;
    width: fit-content;
}

.submissions-table-container>div{
    width: fit-content;
}

.submissions-table-container table{
    border: 1px solid #bdc2d942;
    border-radius: 7px;
    color: white;
}

.submissions-table-container table td,
.submissions-table-container table th{
    padding: 15px;
}
.submissions-table-container table tr{
    background-color: transparent;
}
.submissions-table-container table tr.pair{
    background-color: rgba(255, 255, 255, 0.073);
}