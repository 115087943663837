
.energy-insights .container{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 60px;
    /* padding: 30px; */

}

.insights-image {
    width: 45%;
}

/* Image Styling */
.insights-image img {
  width: 100%;
  border-radius: 10px;
}

/* Content Area */
.insights-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  width: 60%;
}

/* Insight Card */
.card-container{
    display: flex;
    gap: 20px;
}
.insight-card {
  background-color: #48367cff; /* Lighter dark background */
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.insight-card h3 {
  font-size: 1.4rem;
  margin-top: 0;
  margin-bottom: 0px;
  color: var(--color-white);
  margin-bottom: 15px;
  font-family: var(--heading-font-family);
}

.insight-card p {
  color: var(--text-white-cole);
  margin: 0;
  font-size: var(--font-size-lg);
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .insights-content {
    text-align: center;
  }

  .insight-card {
    padding: 15px;
  }
}