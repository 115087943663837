
.login-page{
    min-height: 100vh;
    display: flex;
    align-items: center;
    padding: 30px;
}

.login-page .login-input {
    border: 2px rgba(57, 63, 84, 0.8) solid;
    padding: 30px 15px;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;
    max-width: 450px;
    margin: 0 auto;
    border-radius: 2px;
    /* padding: 1.4rem 2rem 1.6rem; */
    
}

.login-page .login-input button:after {
    content: "";
    position: absolute;
    left: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 999;
    height: 2px;
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
    background-position: 0% 0%;
    background: linear-gradient(to right, #86a8e7, #feb47b, #ff7e5f, #A780FF, #F884BD, #FFA387);
    background-size: 500% auto;
    animation: gradient 3s linear infinite;
}

.login-page .login-input input {
    width: 100%;
    padding: 7px 15px;
    font-size: 1.125rem;
    border: 2px solid;
    /* border-image: linear-gradient(20deg, #ff7e5f 0%, #feb47b 50%, #86a8e7 100%);
    border-image-slice: 1; */
    color: white;

    border-color: #feb47b;
    background: #a680ff20;
    font-weight: 500;
    /* color: #536387; */
    outline: none;
    resize: none;
}
.login-page .login-input input::-webkit-input-placeholder {
    color: #7881A1;
}

.login-page .login-input button {
    /* color: #7881A1; */
    color: #BFD2FF;
    padding: 0 15px;
    height: 55px;
    font-size: 1.5rem;
    line-height: 2.4rem;
    position: relative;
    vertical-align: middle;
    transition: color .25s;
    cursor: pointer;
    transition: .3s;
    background: rgba(33, 35, 48, 0.125);
    border: 2px solid rgba(113, 102, 139, 0.129);
}

.login-page .login-input button:hover {
    background: rgba(57, 63, 84, 0.8);
}

.login-page form h2{
    color: rgba(255, 255, 255, 0.8);
    text-align: center;
    margin-bottom: 0;
}

.login-page form h2 span{
    color: rgb(255, 255, 255);
    text-decoration: underline;
    cursor: pointer;

}