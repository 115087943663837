
.box-container {
    /* padding-left: 50px; */
    padding: 60px;
    padding-top: 10px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    z-index: 2;
    max-width: 1200px;
    background-color: #0F0531;
    border: solid 1px #bbb3d451;
    border-radius: 15px;
}

/* Small */

@media (min-width: 768px) {
    .box-container {
        width: 750px;
    }
}

@media (max-width: 768px) {
    .box-container {
        padding-left: 10px;
        padding-right: 10px;
    }
}


/* Medium */

@media (min-width: 992px) {
    .box-container {
        width: 970px;
    }
}


/* Large */

@media (min-width: 1200px) {
    .box-container {
        width: 1170px;
    }
}

/* Extra Large */

@media (min-width: 1400px) {
    .box-container {
        width: 1370px;
    }
}


/* main title */