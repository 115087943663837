.calculate-savings .inner-container{
    display: flex;
    /* gap: 40px; */
    align-items: center;
    justify-content: space-between;
}

.calculate-savings.section-style{
    padding-top: 80px;
    padding-bottom: 90px;
}

.calculate-savings .inner-container .box:first-child{
    width: 50%;
}

.calculate-savings .inner-container .box:last-child{
    /* flex: 1; */
    width: 35%;
    margin-right: 60px;
}

.calculate-savings .inner-container .box input{
    background-color: transparent;
    border: 2px solid;
    border-image: linear-gradient(20deg, #ff7e5f 0%, #feb47b 50%, #86a8e7 100%);
    border-image-slice: 1;
    /* background: linear-gradient(90deg, #ff7e5f 0%, #feb47b 50%, #86a8e7 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; */
    color: #ff7e5f;
    font-size: var(--font-size-lg);
    padding: 5px 10px;
    width: 100%;
    margin-bottom: 10px;
    outline: none;
}

.calculate-savings .inner-container .box input:focus {
    border-image: unset;
    border-color: #ff7e5f;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.05);
}
.calculate-savings .inner-container .box input::placeholder{
    color: #86a8e7d3;
}
.calculate-savings .inner-container .box input::-ms-input-placeholder{
    color: #86a8e7d3;
}


.calculate-savings .box .inner-content{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
}

.calculate-savings .box .inner-content h4{
    margin: 0;
    font-family: 'Poppins';
    font-weight: 500;
    font-size: var(--font-size-lg);
}

.calculate-savings .box .inner-content h4:last-child{
        background: linear-gradient(90deg, #FFA387, #DA8B73, #996251);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}