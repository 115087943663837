.multistep-form .inner-container{
    display: flex;
    gap: 30px;
    height: 510px;
}

/* .multistep-form .inner-container .box{
    flex: 1;
} */
.multistep-form .inner-container .box.image{
    width: 40%;
    background-image: url('../../assets/_pd7hIMOTnoZhkkijPswY.jpg');
    background-size: cover;
    background-position: center;
}

.multistep-form .inner-container .box.image img{
    width: 100%;
}
.multistep-form .inner-container .box.form{
    width: 50%;
}

.multistep-form form{
    display: flex;
    flex-direction: column;
    height: 220px;
    align-items: stretch;
    justify-content: space-between;
}
.formbold-main-wrapper {
  display: flex;
  align-items: center;
  width: 75%;
  justify-content: center;
}

.formbold-form-wrapper {
  margin: 0 auto;
  /* max-width: 550px; */
  width: 100%;
  /* background: white; */
}

.formbold-steps {
  padding-bottom: 18px;
  margin-bottom: 35px;
  border-bottom: 1px solid #DDE3EC;
}
.formbold-steps ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  gap: 40px;
}
.formbold-steps li {
  display: flex;
  align-items: center;
  gap: 14px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #536387;
}
.formbold-steps li span {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #DDE3EC;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #536387;
}
/* .formbold-steps li.active {
  color: #07074D;;
} */
.formbold-steps li.active span {
  background: #6A64F1;
  color: #FFFFFF;
}

.formbold-input-steps>div:not(:last-child){
    margin-bottom: 15px;
}

.formbold-input-flex {
  display: flex;
  gap: 20px;
  margin-bottom: 22px;
}
.formbold-input-flex > div {
  width: 50%;
}
.formbold-form-input {
    width: 100%;
    padding: 7px 15px;
    font-size: 1.125rem;
    border: 2px solid;
    /* border-image: linear-gradient(20deg, #ff7e5f 0%, #feb47b 50%, #86a8e7 100%);
    border-image-slice: 1; */
    color: white;

    border-color: #feb47b;
    background: #a680ff20;
    font-weight: 500;
    /* color: #536387; */
    outline: none;
    resize: none;
}
.formbold-form-input:focus {
    border-color: #ff7e5f;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.05);
}
.formbold-form-label {
  /* color: #07074D; */
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  display: block;
  margin-bottom: 10px;
}

.formbold-form-confirm {
  border-bottom: 1px solid #DDE3EC;
  padding-bottom: 35px;
}
.formbold-form-confirm p {
  font-size: 16px;
  line-height: 24px;
  color: #536387;
  margin-bottom: 22px;
  width: 75%;
}
.formbold-form-confirm > div {
  display: flex;
  gap: 15px;
}

.formbold-confirm-btn {
  display: flex;
  align-items: center;
  gap: 10px;
  background: #FFFFFF;
  border: 0.5px solid #DDE3EC;
  border-radius: 5px;
  font-size: 16px;
  line-height: 24px;
  color: #536387;
  cursor: pointer;
  padding: 10px 20px;
  transition: all .3s ease-in-out;
}
.formbold-confirm-btn {
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
}
.formbold-confirm-btn.active {
  background: #6A64F1;
  color: #FFFFFF;
}

.formbold-form-step-1,
.formbold-form-step-2,
.formbold-form-step-3 {
  display: none;
}
.formbold-form-step-1.active,
.formbold-form-step-2.active,
.formbold-form-step-3.active {
  display: block;
}

.formbold-form-btn-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 25px;
}
.formbold-back-btn {
  cursor: pointer;
  background: #FFFFFF;
  border: none;
  color: #07074D;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  display: none;
}
.formbold-back-btn.active {
  display: block;
}
.formbold-btn {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 16px;
  border-radius: 5px;
  padding: 10px 25px;
  border: none;
  font-weight: 500;
  background-color: #6A64F1;
  color: white;
  cursor: pointer;
}
.formbold-btn:hover {
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.05);
}

.formbold-progress-bar-wrapper {
    background: linear-gradient(20deg, #ff7e5f 0%, #feb47b 70%, #86a8e7 100%);
    /* background: linear-gradient(45deg, #A780FF, #F884BD); */
    /* background: linear-gradient(to right, #A780FF, #F884BD, #FFA387); */
    /* border-radius: 5px; */
    overflow: hidden;
    height: 5px;
    margin-bottom: 20px;
    position: relative;
}
  
.formbold-progress-bar {
    background: #663399ab;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0; /* Start from the right side */
    transition: width 0.3s ease;
}
.multistep-form .success-message{
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.multistep-form .success-message .success-icon{
    margin: 25px auto;
    width: 120px;
    overflow: hidden;
    aspect-ratio: 1/1;
}
.multistep-form .success-message .success-icon svg{
    width: 100%;
    aspect-ratio: 1/1;  
}
.multistep-form .success-message  svg path{
    fill: url(#myGradient2) #f884bcee;
    /* fill: linear-gradient(6deg, #9A81DF 10%,#7456C8 90%); */
}
.svg-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
.loader-icon{
  margin: 25px auto;
    width: 120px;
    overflow: hidden;
    aspect-ratio: 1/1;
  }
  .loader .loader-icon>svg {
    width: 100%;
    animation: rotate 2s linear infinite;
    aspect-ratio: 1/1;
}

@keyframes rotate {
  from {
      transform: rotate(0deg);
  }
  to {
      transform: rotate(360deg);
  }
}