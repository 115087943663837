/* Root Variables */
a{
  text-decoration: none;
}
html {
  scroll-behavior: smooth;
}

:root {
  /* Colors */
  --color-primary: #38a169; /* Green */
  --color-primary-hover: #2f855a; /* Darker Green */
  --color-secondary: #ebf8ff; /* Light Blue */
  --color-secondary-hover: #f0fff4; /* Light Green */
  --color-text: #2d3748; /* Dark Gray */
  --text-white-cole: #e0d6de; /* Dark Gray */
  --color-white: #ffffff;
  --accent-gradient-background: linear-gradient(6deg, #2e1a66ff 10%, #301f60ff 90%);
  --accent-gradient-background: radial-gradient(circle, rgba(29, 14, 65, 0.9) 0%, rgba(18, 14, 36, 0.95) 90%);


  /* Typography */
  --font-family: 'inter', sans-serif; /* Replace with your preferred font */
  --heading-font-family: 'petrona';
  --font-size-base: 1rem; /* Default font size */
  --font-size-lg: 1.25rem;
  --font-size-l: 2rem;
  --font-size-xl: 2.5rem;
  --font-size-2xl: 3rem;

  /* Spacing */
  --spacing-base: 1rem;
  --spacing-lg: 1.5rem;
  --spacing-xl: 3rem;
  --spacing-l: 2.5rem;

  /* Border Radius */
  --border-radius: 0.5rem;

  /* Box Shadows */
  --box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  /* Transitions */
  --transition: all 0.3s ease-in-out;
}
#logo{
  font-family: 'petrona';
  background: linear-gradient(45deg, #F884BD, #FFA387);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-transform: capitalize;
}


.section-style{
  background: var(--accent-gradient-background);
  color: var(--color-text);
  color: var(--text-white-cole);
  padding-top: var(--spacing-lg);
  padding-bottom: var(--spacing-xl);
  font-family: var(--font-family);
  position: relative;
  z-index: 2;
}

.section-style.box{
  background: #140839;
}
.section-style::after{
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  /* background: var(--color-white); */
  background-color: #0c0524bf;
  /* background-color: #0c0524; */
  /* opacity: 0.5; */
  /* z-index: -1; */
}
.heading{
  font-size: var(--font-size-l);
  /* color: #e4a4d6; */
  background: linear-gradient(to right, #A780FF, #F884BD, #FFA387);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 700;
  margin-bottom: var(--spacing-xl);
  width: fit-content;
  font-family: var(--heading-font-family);
  font-size: var(--font-size-2xl);
}

.small-heading{
  font-size: var(--font-size-lg);
  margin: 0;
  margin-bottom: 10px;
  font-family: var(--heading-font-family);
}

.gradient-link-button {
  display: inline-block; 
  font-family: 'Poppins';
  text-decoration: none;
  background-color: transparent;
  border: 2px solid;
  border-image: linear-gradient(20deg, #ff7e5f 0%, #feb47b 50%, #86a8e7 100%);
  border-image-slice: 1;
  background: linear-gradient(90deg, #ff7e5f 0%, #feb47b 50%, #86a8e7 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 16px;
  font-weight: bold;
  padding: 10px 30px;
  text-transform: capitalize;
  cursor: pointer;
  transition:  .3s ;
  position: relative;
}

.gradient-link-button.small{
  padding: 7px 30px;
  font-weight: 500;
}
.gradient-link-button.back-btn{
  border-image: linear-gradient(20deg, #86a8e7 0%, #feb47b 50%, #ff7e5f 100%);
  border-image-slice: 1;
}

.gradient-link-button::after{
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: transparent;
  transition: .3s;
  /* background-color: #0c0524; */
  /* opacity: 0.5; */
  z-index: -1;
}

.gradient-link-button:hover::after {
  background: white;
  background-color: #ff7f5f1a;
}
