
.testimonial-section {
  text-align: center;
  padding: 50px 20px;
}

.testimonial-container {
  display: flex;
  /* flex-wrap: wrap; */
  justify-content: center;
  margin: auto;
  gap: 30px;
  width: 90%;
}

.testimonial {
    border-radius: 12px;
    overflow: hidden;
    flex: 1;
}

.testimonial-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 8px;
}

.testimonial-name {
  font-size: 1.3rem;
  font-weight: bold;
  margin: 15px 0 10px;
}

.testimonial-text {
  color: var(--text-white-cole);
  margin-bottom: 10px;
}