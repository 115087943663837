.home{
  padding-top: 70px;
  
}

.image-content {
  margin-top: var(--spacing-lg);
  width: fit-content;
}

@media (max-width: 1024px) {
  .image-content {
    margin-top: 0;
    width: 50%;
  }
}

.image-content img {
  width: 500px;
  height: auto;
  aspect-ratio: 1/1; /* Makes the image square */
  object-fit: cover; /* Ensures the image fills the square without distortion */
  border-radius: 20px; /* Optional: Adds rounded corners */
}




/* Description Section Styles */
.description-section {
  padding: var(--spacing-xl) var(--spacing-lg);
  background-color: var(--color-secondary);
  color: var(--color-text);
  font-family: var(--font-family);
}

/* Rows */
.description-section .row {
  margin-bottom: var(--spacing-lg);
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-lg);
  justify-content: center;
}

/* First Row: Text and Video */
.first-row .text-content {
  /* flex: 1; */
  max-width: 600px;
}

.first-row .text-content h2 {
  font-size: var(--font-size-xl);
  font-weight: 700;
  margin-bottom: var(--spacing-base);
}

.first-row .text-content p {
  font-size: var(--font-size-lg);
  line-height: 1.6;
}

.first-row .video-content {
  flex: 1;
  max-width: 600px;
}

.first-row .video-content video {
  width: 100%;
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
}

/* Second Row: Cards */
.second-row {
  justify-content: space-between;
}

.card {
  flex: 1;
  padding: var(--spacing-base);
  background-color: var(--color-white);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  text-align: center;
  transition: var(--transition);
}

.card h3 {
  font-size: var(--font-size-lg);
  margin-bottom: var(--spacing-base);
  color: var(--color-primary);
}

.card p {
  font-size: var(--font-size-base);
  line-height: 1.6;
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
}

/* Third Row: Calculation */
.third-row .calculation {
  background-color: var(--color-white);
  padding: var(--spacing-lg);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  text-align: center;
}

.third-row .calculation h2 {
  font-size: var(--font-size-xl);
  color: var(--color-primary);
  margin-bottom: var(--spacing-base);
}

.third-row .calculation p {
  font-size: var(--font-size-lg);
  line-height: 1.6;
}


.savings-calculator {
  padding: 20px 0;
  text-align: center;
  background-color: var(--bg-light);
}

.calculator-container {
  max-width: 500px;
  margin: 0 auto;
  border: 1px solid var(--border-color);
  border-radius: 8px;
  padding: 20px;  background-color: var(--color-white);
  padding: var(--spacing-lg);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.calculator-container h2 {
  font-size: 1.8rem;
  margin-bottom: 10px;
  color: var(--text-primary);
}

.calculator-container p {
  font-size: 1rem;
  margin-bottom: 20px;
  color: var(--text-secondary);
}

.calculator-form label {
  display: block;
  font-size: 1rem;
  color: var(--text-primary);
  margin-bottom: 5px;
}

.calculator-form input {
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  border: 1px solid var(--border-color);
  border-radius: 5px;
  margin-bottom: 15px;
  box-sizing: border-box;
}

.savings-output p {
  font-size: 1.1rem;
  margin: 5px 0;
  color: var(--text-highlight);
}

.savings-output strong {
  color: var(--text-primary);
}
