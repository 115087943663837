footer{
    background: var(--accent-gradient-background);
    color: var(--color-text);
    color: var(--text-white-cole);
    padding: 40px;
    font-family: var(--font-family);
    position: relative;
    z-index: 2;
}

footer h3{
    text-align: center;
}

footer h3 a{
    color: #A780FF;
    text-transform: capitalize;
}