.installation .inner-container{
    display: flex;
}
.installation .inner-container .box{
    display: flex;
}
.installation .step-number {
    /* background-color: #6C4AB6; */
    color: #fff;
    font-size: 1.2rem;
    font-weight: bold;
    border-radius: 5px;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 1rem;
    position: relative;
}

.installation .step-number::after{
    content: '';
    position: absolute;
    height: 45px;
    width: 45px;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    background-color: #3a325ba8;
    border-radius: 5px;
    z-index: -1;
    border: solid 1px #4f4e5ea8;
}

.installation .box h3{
    font-family: 'pentora';
    margin-top: 10px;
    font-size: 1.5rem;
}