.access-control{
    padding-bottom: 90px;
}

.access-control .inner-container{
    display: flex;
    gap: 20px;
}

.access-control .inner-container .box h3{
    font-size: var(--font-size-lg);
    font-size: 1.6rem;
}
.access-control .inner-container .box p{
    font-size: 1.2rem;
    line-height: 1.8rem;
    margin: 0;
}
.access-control .icon{
    margin-bottom: 30px;
}
.access-control .icon-wrapper{
    width: 45px;
    overflow: hidden;
    aspect-ratio: 1/1;
}
.access-control .icon-wrapper svg{
    width: 100%;
    aspect-ratio: 1/1;
}
.access-control .icon-wrapper svg path{
    fill: url(#myGradient) #876cd4ff;
    /* fill: linear-gradient(6deg, #9A81DF 10%,#7456C8 90%); */
}