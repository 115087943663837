header{
    background-color: #0a041ce7;
    -webkit-backdrop-filter: blur(2px);
    backdrop-filter: blur(5px);
    position: fixed;
    z-index: 9999;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    width: 100vw;
    height: 70px;
    display: flex;
    align-items: center;
    top: 0;
}
header .container{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

header #logo{
    margin: 0;
}

header .gradient-link-button{
    padding: 8px 30px;
}